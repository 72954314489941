    var dialog;
; (function($, undefined) {

    var isMobile = function() {
        return window.innerWidth < 970;
    }

    var handleMenuItemClick = function(e) {
        if(isMobile()) {
            toggleMenuItemOpened.call(this, e);
        }
    }

    var toggleMenuItemOpened = function(e) {
        e.preventDefault();
        $(this).parent().toggleClass('menu-item--opened');
    }

    var triggerSearch = function(term) {
        $('.jsMenuBarSearch').submit();
    }

    var handleSearchTerm = function() {
        var $this = $('.jsSearchTerm');
        var isOpened = !$this.hasClass('search__term--hidden');
        var hasText = $this.val().trim().length;

        if(isOpened && hasText) {
            triggerSearch();
        } else {
            toggleSearchTerm.call($this);
        }
        $this.focus();
    }

    var toggleSearchTerm = function() {
        $('.jsSearchTerm').toggleClass('search__term--hidden');
    }

    var toggleMenuBar = function() {
        $('.jsMenuBar').toggleClass('menu-bar--opened');
        $(this).toggleClass('header__menu--opened');
    }

    var showCampuses = function() {
        var selectedProgramID = $(this).find(':selected').val();
        var $selectCampus = $('[name=CurriculumID' + selectedProgramID + ']');
        $('.jsSelectProgram,.jsSelectedCampus').addClass('hidden').prop('disabled', true).val();
        $selectCampus.removeClass('hidden').prop('disabled', false);
    }

    var togglePlaceholder = function() {
        var $this = $(this);

        setTimeout(function() {
            var isPhone = $this.hasClass('phoneMask');
            var hasText = $this.val().length > 0;

            if(isPhone) {
                hasText = $this.val().replace(/\D/g,'').length;
            }

            $this.toggleClass('filled', !!hasText);
        }, 0);
    }

    var showMoreConsent = function() {
        $('.jsWhySouthern').addClass('main__southern-box--expanded');
        $(this).parent().find('.request-information__more-consent').removeClass('hidden');
        $('.request-information__show-more-consent-dots').remove();
        $(this).remove();
    }

    var showRequestInformationModal = function() {
        $('.request-information__mobile-button').addClass('hidden');
        $('.overlay').appendTo('.main-right');
        $('.overlay').removeClass('hidden').removeAttr('z-index');
        $('.main__wrapper').css('z-index', 70);

        dialog = new A11yDialog($('.main__right')[0]);
        dialog.show();

        // fixed popup only when device height is greater than popup with full consent message
        if(window.innerHeight < 820) {
            $('.main__right').css('position', 'relative');
            $('.request-information').css('position', 'absolute');
            var topPosition = $('.main__right').position().top;
            $('.request-information').css('top', topPosition * -1 + 302 + $(window).scrollTop());
            $('.overlay').appendTo('body').css('z-index', 46);
        }
    }

    var cancelRequestInformationModal = function() {
        $('.request-information__mobile-button').removeClass('hidden');
        $('.overlay').addClass('hidden');
        $('.main__wrapper').removeAttr('style');
        $('.main__right').removeAttr('style');
        $('.request-information').removeAttr('style');
        dialog.hide();
    }

    var toggleSouthernContent = function() {
        var $this = $(this);
        var isOpen = !$this.hasClass('main__why-southern--collapsed');

        if(isOpen) {
            $this.addClass('main__why-southern--collapsed');
            $this.parent().addClass('main__southern-content--collapsed');
        } else {
            $('.wp-block-stc-accordion').addClass('main__southern-content--collapsed');
            $('.wp-block-stc-accordion h3').addClass('main__why-southern--collapsed');
            $this.removeClass('main__why-southern--collapsed');
            $this.parent().removeClass('main__southern-content--collapsed');
        }

        var thisPosition = Number($this.offset().top);
        var scrollPosition = Number($(window).scrollTop());

        if(scrollPosition > thisPosition) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $this.offset().top
            }, 250);
        }
    }

    var toggleCampusAccordionTitle = function() {
        var $this = $(this);
        var isExpanded = $this.hasClass('campus-accordion__title--expanded');
        if(!isExpanded) {
            $('.main__campus-accordion--expanded,.campus-accordion__title--expanded').removeClass('main__campus-accordion--expanded campus-accordion__title--expanded');
        }
        $this.toggleClass('campus-accordion__title--expanded');
        $this.parent().toggleClass('main__campus-accordion--expanded');

        var thisPosition = Number($this.offset().top);
        var scrollPosition = Number($(window).scrollTop());

        if(scrollPosition > thisPosition) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $this.offset().top
            }, 250);
        }

        calendarActivate();
    }

    var calendarActivate = function() {
        var $calendar = $('#calendar');
        if($calendar.is(':visible')) {
            $('#calendar').fullCalendar('render');

            calendarActivate = $.noop;
        }
    }

    $('.jsSearchButton').on('click', handleSearchTerm);
    $('.jsMobileMenu').on('click', toggleMenuBar);
    $('#menu-top-menu > li').hover(function() { $(this).find('a').first().addClass('hover'); }, function() { $(this).find('a').first().removeClass('hover'); } );
    $('.jsSelectCampaign').on('change', showCampuses);
    $('.jsRequestInput').on('keyup blur focus', togglePlaceholder);
    $('.jsShowMoreConsent').one('click', showMoreConsent);
    $('#menu-top-menu > .menu-item-has-children > a, #menu-top-menu > .menu-item-has-children > .sub-menu > .menu-item-has-children > a').on('click', handleMenuItemClick);

    $('.jsShowRequestInformationModal').on('click', showRequestInformationModal);
    $('.jsCancelRequestInformationModal').on('click', cancelRequestInformationModal);

    $('.jsSouthernContent').on('click', toggleSouthernContent);
    $('.jsCampusAccordionTitle').on('click', toggleCampusAccordionTitle);

    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,

        fixedContentPos: false,

        iframe: {
            patterns: {
                youtube: {
                    id: function ( url ) {
                        if ( -1 !== url.indexOf( "v=" ) ) {
                            return url.match( /v=([^?&\s]+)/ )[1];
                        } else {
                            let regex = new RegExp( '/embed/([^?&\\s]+)' );
                            return url.match( regex )[1];
                        }
                    }
                }
            }
        }
    });

    $(window).keyup(function(e) {
        var $this = $(':focus');
        if ($this.closest('.menu-item').length === 0) {
            $('.hover').removeClass('hover');
        } else if ($this.is('#menu-top-menu > .menu-item-object-page > a')) {
            $('.hover').removeClass('hover');
            $(':focus').addClass('hover');
            $(':focus').parent().addClass('hover');
        }
    });

    $(document).on('keydown', '.jsCampusAccordionTitle', function(e) {
        var $target = $(e.target);
        var code = (e.keyCode ? e.keyCode : e.which);
        if((code === 32 || code === 13) && !$target.is('a')) {
            $(this).click();
            return false;
        }

    });


})(jQuery);
